import { render, staticRenderFns } from "./firestickInstallationInstructionsRegularAnd4K.vue?vue&type=template&id=7fb93f35&scoped=true"
import script from "./firestickInstallationInstructionsRegularAnd4K.vue?vue&type=script&lang=js"
export * from "./firestickInstallationInstructionsRegularAnd4K.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fb93f35",
  null
  
)

export default component.exports