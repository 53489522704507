<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-10">
								<p class="font-semibold">Filelinked is no longer in service. Please see the following updated installation instructions.</p>
								<div>
									<p>In this guide we will show you how to install Downloader app in order to Download {{ $store.state.brandInfo.appName }}.</p>
									<p>
										We highly recommend you use Downloader to install {{ $store.state.brandInfo.appName }}, this will ensure that your
										{{ $store.state.brandInfo.appName }} app will stay updated.
									</p>
								</div>
								<div class="space-y-2">
									<p>1. From the Main Menu scroll to the right and select the settings menu.</p>
									<img src="../../../assets/images/installation/settings.png" alt="settings" />
								</div>
								<div class="space-y-2">
									<p>2. Select My Fire TV.</p>
									<img src="../../../assets/images/installation/settings2.png" alt="settings2" />
								</div>
								<div class="space-y-2">
									<p>3. Scroll down and Choose About Option.</p>
									<img src="../../../assets/images/installation/about.png" alt="developer-options" />
								</div>
								<div class="space-y-2">
									<p>4. Scroll down and Choose Developer Options.</p>
									<img src="../../../assets/images/installation/developer-options.png" alt="developer-options" />
								</div>
								<div class="space-y-2">
									<p>5. Select Apps from Unknown Sources.</p>
									<img src="../../../assets/images/installation/apps-form-unknown-sources.png" alt="apps-form-unknown-sources" />
								</div>
								<div class="space-y-2">
									<p>6. Select Turn On.</p>
									<img src="../../../assets/images/installation/apps-form-unknown-sources2.png" alt="apps-form-unknown-sources" />
								</div>

								<div class="space-y-2">
									<p class="w-9/12">
										7. Return to the home screen and hover over the Search icon. (<span class="font-semibold"
											>If you already have the Downloader app installed you may skip to step 13 below</span
										>)
									</p>
									<img src="../../../assets/images/installation/settings3.png" alt="apps-form-unknown-sources" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">8. Type in Downloader.</p>
									<img src="../../../assets/images/installation/search-and-download.png" alt="search-and-download" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">9. Select the Downloader app</p>
									<img src="../../../assets/images/installation/apps-download.png" alt="search-and-download" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">10. Select Download</p>
									<img src="../../../assets/images/installation/download-review-btnchange.png" alt="download-review-btnchange" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">11. Select Open</p>
									<img src="../../../assets/images/installation/download-and-review.png" alt="download-and-review" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">12. Select Allow when prompted</p>
									<img src="../../../assets/images/installation/quick-start-guide.png" alt="quick-start-guide" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">13. Select OK</p>
									<img src="../../../assets/images/installation/quick-start-guide2.png" alt="quick-start-guide" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">14. Select search option.</p>
									<img src="../../../assets/images/installation/search-download.png" class="w-9/12" alt="quick-start-guide" />
								</div>
								<div class="space-y-2">
									<p v-if="$store.state.brandInfo.name === 'apollogroup'" class="w-9/12">15. Enter code 13936 and then select the GO button</p>
									<div class="space-y-1">
										<p>
											If not working enter link:
											<a :href="$store.state.brandInfo.appTvApk"
												><span class="bg-gray-800 text-lg p-1 text-white">{{ $store.state.brandInfo.appTvApk }}</span></a
											>
											and then select the GO button
										</p>
									</div>
									<img src="../../../assets/images/installation/download-file.png" alt="quick-start-guide" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">16. If you entered the URL correctly the download will begin. Wait until the download is finished.</p>
									<img src="../../../assets/images/installation/download-file.png" class="w-9/12" alt="quick-start-guide" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">17. Select Install</p>
									<img src="../../../assets/images/installation/select-install.png" class="w-9/12" alt="select-install" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">18. Select Done.</p>
									<img src="../../../assets/images/installation/install-done.png" class="w-9/12" alt="install-done" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">19. This will take you back to Downloader. Click Delete.</p>
									<img src="../../../assets/images/installation/delete-and-install.png" class="w-9/12" alt="delete-and-install" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">20. Click Delete again to confirm.</p>
									<img src="../../../assets/images/installation/confirm-delete.png" class="w-9/12" alt="delete-and-install" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">21. Return to the home screen menu (press the home button on your remote).</p>
									<img src="../../../assets/images/installation/remote-home-button.png" class="w-6/12" alt="delete-and-install" />
								</div>
								<div class="space-y-2">
									<p class="w-9/12">
										22. Then scroll down to the “Your Apps & Channels” sub header. Scroll all the way to the left and select the “See All” icon. If you don't
										have this icon, you may skip this step.
									</p>
									<img src="../../../assets/images/installation/home-see-all.png" alt="delete-and-install" />
								</div>
								<div class="space-y-2">
									<div>
										<p class="w-9/12">
											23. Scroll to the bottom of your apps list and hover over the {{ $store.state.brandInfo.appName }}
											{{ $store.state.brandInfo.brandName }} tv appl icon and then click Options button
										</p>
										<p class="w-9/12">(3 horizontal lines on your remote control)</p>
									</div>
									<img
										v-if="$store.state.brandInfo.name === 'apollogroup'"
										src="../../../assets/images/installation/your-apps&channel.jpg"
										class="w-9/12"
										alt="delete-and-install"
									/>
									<img
										v-if="$store.state.brandInfo.name === 'pizzatime'"
										src="../../../assets/images/installation/your-apps&channelPizza.png"
										class="w-9/12"
										alt="delete-and-install"
									/>
								</div>
								<div class="space-y-2">
									<p>24. Click Move to front (Click Menu button on remote (3 horizontal lines), then choose “Move to Front” from the list),</p>
									<img src="../../../assets/images/installation/return-list.png" alt="move-to-front" />
									<img src="../../../assets/images/installation/move-to-front.png" alt="move-to-front" />
								</div>
								<div class="space-y-1">
									<p>25. Click the home button on your remote and then select the {{ $store.state.brandInfo.appName }} app icon</p>
									<p>26. Loading screen please wait</p>
									<img src="../../../assets/images/installation/aplloGroupTv.png" class="w-9/12" alt="aplloGroupTv" />
								</div>
								<div v-if="$store.state.brandInfo.name === 'apollogroup'" class="space-y-1">
									<p>27. Use your remote to enter your Email or Username in the [Email or Username] field (Top line)</p>
									<img src="../../../assets/images/installation/email-or-username.png" alt="aplloGroupTv" />
								</div>
								<div class="space-y-1">
									<p v-if="$store.state.brandInfo.name === 'apollogroup'">Your Password is case-sensitive!</p>
									<p v-if="$store.state.brandInfo.name === 'apollogroup'">
										28. After entering your Email/Username click the <span class="font-semibold">NEXT</span> Button and then enter your password in the
										[Password] field (bottom line)
									</p>
									<p v-if="$store.state.brandInfo.name === 'pizzatime'">Enter your MU3 link and click continue.</p>

									<img v-if="$store.state.brandInfo.name === 'apollogroup'" src="../../../assets/images/installation/signin.png" alt="signin" class="w-9/12" />
									<img
										v-if="$store.state.brandInfo.name === 'pizzatime'"
										src="../../../assets/images/installation/signinPlayberry.png"
										alt="signin"
										class="w-9/12"
									/>
								</div>
								<div>
									<p v-if="$store.state.brandInfo.name === 'apollogroup'" class="my-5">
										29. After entering your Password click the <span class="font-semibold">LOGIN</span> Button.
									</p>
									<div class="space-y-5">
										<p class="font-semibold">IMPORTANT NOTES:</p>
										<div class="space-y-1">
											<p>The <span class="font-semibold">Profile and Settings menu</span> is found in the top left corner icon of the app.</p>
											<p>In the the settings menu you can find additional information and settings below</p>
											<p>-App Version #</p>
											<p>-Speedtest</p>
											<p>-Clear Cache button</p>
											<p>-Log out Button</p>
											<p>-Expiry Date</p>
											<p>-Time Zone (Use -/+ buttons to change)</p>
											<p>
												-Save login checkbox (to save your login information. Uncheck this box and log out of the app if using the app on a friends or
												family members device temporarily.
											</p>
											<p>-Categories (select your desired categories or uncheck to remove category)</p>
											<p>-Time Format 12h or 24h</p>
											<p>-Player settings (change player if experiencing issues with certain content)</p>
											<p>-Default Language</p>
											<p>-Text Size (for subtitles only)</p>
											<p>To add content to your "My List" select the content and long press the ok/select middle circle button on your remote.</p>
											<p>Enjoy!</p>
										</div>
									</div>
								</div>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'firestickInstallationInstructionsRegularAnd4K',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
